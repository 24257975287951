@import './styles/variables';
@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:wght@300;400;500;600;700;800;900&Cinzel:wght@300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html {
  font-size: 8px;
  @media screen and (min-height: 800px) {
    font-size: 7.4px;
  }
  @media screen and (min-height: 850px) {
    font-size: 7.87px;
  }
  @media screen and (min-height: 900px) {
    font-size: 8.33px;
  }
  @media screen and (min-height: 950px) {
    font-size: 8.8px;
  }
  @media screen and (min-height: 1000px) {
    font-size: 9.26px;
  }
  @media screen and (min-height: 1050px) {
    font-size: 9.72px;
  }
  @media screen and (min-height: 1100px) {
    font-size: 10.19px;
  }
  @media screen and (min-height: 1150px) {
    font-size: 10.65px;
  }
  @media screen and (min-height: 1200px) {
    font-size: 11.26px;
  }
  @media screen and (min-height: 1300px) {
    font-size: 12.04px;
  }
  @media screen and (min-height: 1400px) {
    font-size: 12.96px;
  }
  @media screen and (min-height: 1400px) {
    font-size: 12.96px;
  }
}
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */

::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
  height: 8px;
}

/* background of the scrollbar except button or resizer */

::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 6px;
  border: 2px solid #fff;
  and:horizontal {
    background-color: #12c8b2;
  }
}

/* set button(top and bottom of the scrollbar) */

::-webkit-scrollbar-button {
  display: none;
}
.medium-editor-placeholder {
  margin-bottom: 0 !important;
  line-height:100%;
  
}
.medium-editor-placeholder-relative:after, .medium-editor-placeholder:after{
  position: relative !important;
  font-style: normal !important;
  line-height:100%;
  color: #8090E7 !important;
  pointer-events: none;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  line-height: 1.6;
  padding-top: 6.4rem;
  color: $primary-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ant-notification {
  z-index: $notification-z-index;
}

$spaces-list: (
  'auto': auto,
  '0': 0,
  '4': '0.4rem',
  '8': 0.8rem,
  '10': 1rem,
  '12': 1.2rem,
  '14': 1.4rem,
  '16': 1.6rem,
  '17': 1.7rem,
  '20': 2rem,
  '22': 2.2rem,
  '24': 2.4rem,
  '30': 3rem,
  '32': 3.2rem,
  '40': 4rem,
  '42': 4.2rem,
  '48': 4.8rem,
  '50': 5rem,
  '60': 6rem,
  '65': 6.5rem,
  '70': 7rem,
);
@each $name, $space in $spaces-list {
  .p-#{$name} {
    padding-top: #{$space} !important;
    padding-bottom: #{$space} !important;
    padding-left: #{$space} !important;
    padding-right: #{$space} !important;
  }
  .pv-#{$name} {
    padding-top: #{$space} !important;
    padding-bottom: #{$space} !important;
  }
  .ph-#{$name} {
    padding-left: #{$space} !important;
    padding-right: #{$space} !important;
  }
  .pt-#{$name} {
    padding-top: #{$space} !important;
  }
  .pb-#{$name} {
    padding-bottom: #{$space} !important;
  }
  .pl-#{$name} {
    padding-left: #{$space} !important;
  }
  .pr-#{$name} {
    padding-right: #{$space} !important;
  }
  .m-#{$name} {
    margin-top: #{$space} !important;
    margin-bottom: #{$space} !important;
    margin-left: #{$space} !important;
    margin-right: #{$space} !important;
  }
  .mv-#{$name} {
    margin-top: #{$space} !important;
    margin-bottom: #{$space} !important;
  }
  .mh-#{$name} {
    margin-left: #{$space} !important;
    margin-right: #{$space} !important;
  }
  .mt-#{$name} {
    margin-top: #{$space} !important;
  }
  .mb-#{$name} {
    margin-bottom: #{$space} !important;
  }
  .ml-#{$name} {
    margin-left: #{$space} !important;
  }
  .mr-#{$name} {
    margin-right: #{$space} !important;
  }
}
/* MOVE TO styled please*/

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}