$bg: #1c1c1c;
$white: #fff;
$grey: #333333;
$grey-1: #8d8d8d;
$btn-grey: #656565;
$pink: #ff2e56;
$orange: #ffb931;
$orange-dark: #d89719;
$orange-dark1: #aa7306;
$red: #aa122b;
$red-dark: #750819;
$text: #afafaf;
$fb: #4267b2;
$breakpoint-mobile-small-1: 320px;
$breakpoint-mobile-small: 360px;
$breakpoint-mobile-big: 525px;
$breakpoint-mobile-big-1: 625px;
$breakpoint-tablet-small: 700px;
$breakpoint-tablet: 768px;
$big-screen-1: 1000px;
$big-screen: 1220px;
$big-screen-2: 2560px;
$height-breakpoint-rotate-mobile: 500px;
$default-color: #7335cb;
$secondary-color: #12c8b2;
$primary-color: #152370;
$success-color: #39c521;
$notification-z-index: 3001;
