@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:wght@300;400;500;600;700;800;900&Cinzel:wght@300;400;500;600;700;800;900&display=swap);
/* purgecss start ignore */

@font-face{
  font-family:"Roca One";

  src:url(/static/media/RocaOne.7528f7fe.otf)
}

.app,#root{
  height:100%;
  width:100%
}

.app{
  display:flex;
  flex-direction:column
}

img{
  max-width:100%
}

/* purgecss end ignore */
/* purgecss start ignore */

.loader{
  color:#fff;
  font-size:9rem;
  text-indent:-9999em;
  overflow:hidden;
  width:1em;
  height:1em;
  border-radius:50%;
  margin:7.2rem auto;
  position:relative;
  -webkit-transform:translateZ(0);
  transform:translateZ(0);
  -webkit-animation:load6 1.7s infinite ease,round 1.7s infinite ease;
  animation:load6 1.7s infinite ease,round 1.7s infinite ease
}

.loader-wrapper{
  position:fixed;
  bottom:0;
  width:100vw;
  height:100vh;
  align-items:center;
  display:flex;
  -webkit-backdrop-filter:blur(3rem);
  backdrop-filter:blur(3rem)
}

@-webkit-keyframes load6{
  0%{
    box-shadow:0 -0.83em 0 -0.4em,0 -0.83em 0 -0.42em,0 -0.83em 0 -0.44em,0 -0.83em 0 -0.46em,0 -0.83em 0 -0.477em
  }

  5%,95%{
    box-shadow:0 -0.83em 0 -0.4em,0 -0.83em 0 -0.42em,0 -0.83em 0 -0.44em,0 -0.83em 0 -0.46em,0 -0.83em 0 -0.477em
  }

  10%,59%{
    box-shadow:0 -0.83em 0 -0.4em,-0.087em -0.825em 0 -0.42em,-0.173em -0.812em 0 -0.44em,-0.256em -0.789em 0 -0.46em,-0.297em -0.775em 0 -0.477em
  }

  20%{
    box-shadow:0 -0.83em 0 -0.4em,-0.338em -0.758em 0 -0.42em,-0.555em -0.617em 0 -0.44em,-0.671em -0.488em 0 -0.46em,-0.749em -0.34em 0 -0.477em
  }

  38%{
    box-shadow:0 -0.83em 0 -0.4em,-0.377em -0.74em 0 -0.42em,-0.645em -0.522em 0 -0.44em,-0.775em -0.297em 0 -0.46em,-0.82em -0.09em 0 -0.477em
  }

  100%{
    box-shadow:0 -0.83em 0 -0.4em,0 -0.83em 0 -0.42em,0 -0.83em 0 -0.44em,0 -0.83em 0 -0.46em,0 -0.83em 0 -0.477em
  }
}

@keyframes load6{
  0%{
    box-shadow:0 -0.83em 0 -0.4em,0 -0.83em 0 -0.42em,0 -0.83em 0 -0.44em,0 -0.83em 0 -0.46em,0 -0.83em 0 -0.477em
  }

  5%,95%{
    box-shadow:0 -0.83em 0 -0.4em,0 -0.83em 0 -0.42em,0 -0.83em 0 -0.44em,0 -0.83em 0 -0.46em,0 -0.83em 0 -0.477em
  }

  10%,59%{
    box-shadow:0 -0.83em 0 -0.4em,-0.087em -0.825em 0 -0.42em,-0.173em -0.812em 0 -0.44em,-0.256em -0.789em 0 -0.46em,-0.297em -0.775em 0 -0.477em
  }

  20%{
    box-shadow:0 -0.83em 0 -0.4em,-0.338em -0.758em 0 -0.42em,-0.555em -0.617em 0 -0.44em,-0.671em -0.488em 0 -0.46em,-0.749em -0.34em 0 -0.477em
  }

  38%{
    box-shadow:0 -0.83em 0 -0.4em,-0.377em -0.74em 0 -0.42em,-0.645em -0.522em 0 -0.44em,-0.775em -0.297em 0 -0.46em,-0.82em -0.09em 0 -0.477em
  }

  100%{
    box-shadow:0 -0.83em 0 -0.4em,0 -0.83em 0 -0.42em,0 -0.83em 0 -0.44em,0 -0.83em 0 -0.46em,0 -0.83em 0 -0.477em
  }
}

@-webkit-keyframes round{
  0%{
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg)
  }

  100%{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}

@keyframes round{
  0%{
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg)
  }

  100%{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}

/* purgecss end ignore */
.visible {
  visibility: visible !important
}

.invisible {
  visibility: hidden !important
}

.static {
  position: static !important
}

.fixed {
  position: fixed !important
}

.absolute {
  position: absolute !important
}

.relative {
  position: relative !important
}

.sticky {
  position: sticky !important
}

.top-0 {
  top: 0px !important
}

.top-1 {
  top: 0.25rem !important
}

.-top-8 {
  top: -2rem !important
}

.top-3\/4 {
  top: 75% !important
}

.right-0 {
  right: 0px !important
}

.right-2 {
  right: 0.5rem !important
}

.bottom-10 {
  bottom: 2.5rem !important
}

.-bottom-12 {
  bottom: -3rem !important
}

.left-14 {
  left: 3.5rem !important
}

.left-52 {
  left: 13rem !important
}

.z-10 {
  z-index: 10 !important
}

.float-right {
  float: right !important
}

.m-0 {
  margin: 0px !important
}

.m-2 {
  margin: 0.5rem !important
}

.m-32 {
  margin: 8rem !important
}

.m-auto {
  margin: auto !important
}

.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important
}

.mt-0 {
  margin-top: 0px !important
}

.mt-2 {
  margin-top: 0.5rem !important
}

.mt-3 {
  margin-top: 0.75rem !important
}

.mt-4 {
  margin-top: 1rem !important
}

.mt-5 {
  margin-top: 1.25rem !important
}

.mt-6 {
  margin-top: 1.5rem !important
}

.mt-7 {
  margin-top: 1.75rem !important
}

.mt-8 {
  margin-top: 2rem !important
}

.mt-10 {
  margin-top: 2.5rem !important
}

.mt-11 {
  margin-top: 2.75rem !important
}

.mt-12 {
  margin-top: 3rem !important
}

.mt-14 {
  margin-top: 3.5rem !important
}

.mt-16 {
  margin-top: 4rem !important
}

.mt-20 {
  margin-top: 5rem !important
}

.mt-24 {
  margin-top: 6rem !important
}

.mt-32 {
  margin-top: 8rem !important
}

.mt-36 {
  margin-top: 9rem !important
}

.mt-40 {
  margin-top: 10rem !important
}

.mt-52 {
  margin-top: 13rem !important
}

.mr-0 {
  margin-right: 0px !important
}

.mr-2 {
  margin-right: 0.5rem !important
}

.mr-3 {
  margin-right: 0.75rem !important
}

.mr-4 {
  margin-right: 1rem !important
}

.mr-5 {
  margin-right: 1.25rem !important
}

.mr-6 {
  margin-right: 1.5rem !important
}

.mr-8 {
  margin-right: 2rem !important
}

.mr-10 {
  margin-right: 2.5rem !important
}

.mr-14 {
  margin-right: 3.5rem !important
}

.mr-16 {
  margin-right: 4rem !important
}

.mr-20 {
  margin-right: 5rem !important
}

.mr-24 {
  margin-right: 6rem !important
}

.mr-32 {
  margin-right: 8rem !important
}

.mr-40 {
  margin-right: 10rem !important
}

.mr-60 {
  margin-right: 15rem !important
}

.mr-3\.5 {
  margin-right: 0.875rem !important
}

.mb-0 {
  margin-bottom: 0px !important
}

.mb-2 {
  margin-bottom: 0.5rem !important
}

.mb-3 {
  margin-bottom: 0.75rem !important
}

.mb-4 {
  margin-bottom: 1rem !important
}

.mb-5 {
  margin-bottom: 1.25rem !important
}

.mb-6 {
  margin-bottom: 1.5rem !important
}

.mb-8 {
  margin-bottom: 2rem !important
}

.mb-10 {
  margin-bottom: 2.5rem !important
}

.mb-12 {
  margin-bottom: 3rem !important
}

.mb-14 {
  margin-bottom: 3.5rem !important
}

.mb-16 {
  margin-bottom: 4rem !important
}

.mb-20 {
  margin-bottom: 5rem !important
}

.mb-24 {
  margin-bottom: 6rem !important
}

.mb-28 {
  margin-bottom: 7rem !important
}

.mb-32 {
  margin-bottom: 8rem !important
}

.mb-40 {
  margin-bottom: 10rem !important
}

.mb-60 {
  margin-bottom: 15rem !important
}

.mb-80 {
  margin-bottom: 20rem !important
}

.mb-2\.5 {
  margin-bottom: 0.625rem !important
}

.ml-3 {
  margin-left: 0.75rem !important
}

.ml-4 {
  margin-left: 1rem !important
}

.ml-5 {
  margin-left: 1.25rem !important
}

.ml-8 {
  margin-left: 2rem !important
}

.ml-10 {
  margin-left: 2.5rem !important
}

.ml-12 {
  margin-left: 3rem !important
}

.ml-14 {
  margin-left: 3.5rem !important
}

.ml-16 {
  margin-left: 4rem !important
}

.ml-20 {
  margin-left: 5rem !important
}

.ml-24 {
  margin-left: 6rem !important
}

.ml-32 {
  margin-left: 8rem !important
}

.ml-44 {
  margin-left: 11rem !important
}

.ml-auto {
  margin-left: auto !important
}

.block {
  display: block !important
}

.inline-block {
  display: inline-block !important
}

.inline {
  display: inline !important
}

.flex {
  display: flex !important
}

.inline-flex {
  display: inline-flex !important
}

.table {
  display: table !important
}

.grid {
  display: grid !important
}

.hidden {
  display: none !important
}

.h-8 {
  height: 2rem !important
}

.h-12 {
  height: 3rem !important
}

.h-16 {
  height: 4rem !important
}

.h-24 {
  height: 6rem !important
}

.h-28 {
  height: 7rem !important
}

.h-72 {
  height: 18rem !important
}

.h-full {
  height: 100% !important
}

.h-screen {
  height: 100vh !important
}

.max-h-96 {
  max-height: 24rem !important
}

.w-72 {
  width: 18rem !important
}

.w-96 {
  width: 24rem !important
}

.w-auto {
  width: auto !important
}

.w-1\/2 {
  width: 50% !important
}

.w-3\/5 {
  width: 60% !important
}

.w-4\/5 {
  width: 80% !important
}

.w-5\/6 {
  width: 83.333333% !important
}

.w-11\/12 {
  width: 91.666667% !important
}

.w-full {
  width: 100% !important
}

.w-screen {
  width: 100vw !important
}

.w-max {
  width: -webkit-max-content !important;
  width: max-content !important
}

.max-w-xs {
  max-width: 20rem !important
}

.max-w-sm {
  max-width: 24rem !important
}

.max-w-full {
  max-width: 100% !important
}

.flex-shrink-0 {
  flex-shrink: 0 !important
}

.flex-shrink {
  flex-shrink: 1 !important
}

.flex-grow {
  flex-grow: 1 !important
}

.border-collapse {
  border-collapse: collapse !important
}

.transform {
  --tw-translate-x: 0 !important;
  --tw-translate-y: 0 !important;
  --tw-rotate: 0 !important;
  --tw-skew-x: 0 !important;
  --tw-skew-y: 0 !important;
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.cursor-default {
  cursor: default !important
}

.cursor-pointer {
  cursor: pointer !important
}

.cursor-not-allowed {
  cursor: not-allowed !important
}

.select-none {
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important
}

.resize {
  resize: both !important
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important
}

.flex-row {
  flex-direction: row !important
}

.flex-col {
  flex-direction: column !important
}

.flex-wrap {
  flex-wrap: wrap !important
}

.content-center {
  align-content: center !important
}

.items-start {
  align-items: flex-start !important
}

.items-center {
  align-items: center !important
}

.justify-start {
  justify-content: flex-start !important
}

.justify-end {
  justify-content: flex-end !important
}

.justify-center {
  justify-content: center !important
}

.justify-between {
  justify-content: space-between !important
}

.gap-2 {
  gap: 0.5rem !important
}

.gap-3 {
  gap: 0.75rem !important
}

.gap-4 {
  gap: 1rem !important
}

.gap-6 {
  gap: 1.5rem !important
}

.gap-8 {
  gap: 2rem !important
}

.gap-10 {
  gap: 2.5rem !important
}

.gap-12 {
  gap: 3rem !important
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important
}

.self-start {
  align-self: flex-start !important
}

.self-end {
  align-self: flex-end !important
}

.self-center {
  align-self: center !important
}

.overflow-auto {
  overflow: auto !important
}

.overflow-hidden {
  overflow: hidden !important
}

.overflow-visible {
  overflow: visible !important
}

.overflow-y-auto {
  overflow-y: auto !important
}

.overflow-x-hidden {
  overflow-x: hidden !important
}

.overflow-y-scroll {
  overflow-y: scroll !important
}

.overscroll-contain {
  -ms-scroll-chaining: none !important;
      overscroll-behavior: contain !important
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important
}

.whitespace-nowrap {
  white-space: nowrap !important
}

.break-all {
  word-break: break-all !important
}

.rounded {
  border-radius: 0.25rem !important
}

.rounded-lg {
  border-radius: 0.5rem !important
}

.border {
  border-width: 1px !important
}

.border-none {
  border-style: none !important
}

.bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important
}

.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important
}

.bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important
}

.p-0 {
  padding: 0px !important
}

.p-1 {
  padding: 0.25rem !important
}

.p-4 {
  padding: 1rem !important
}

.p-5 {
  padding: 1.25rem !important
}

.p-6 {
  padding: 1.5rem !important
}

.p-8 {
  padding: 2rem !important
}

.p-10 {
  padding: 2.5rem !important
}

.p-14 {
  padding: 3.5rem !important
}

.p-16 {
  padding: 4rem !important
}

.p-20 {
  padding: 5rem !important
}

.p-24 {
  padding: 6rem !important
}

.p-32 {
  padding: 8rem !important
}

.p-48 {
  padding: 12rem !important
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important
}

.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important
}

.px-12 {
  padding-left: 3rem !important;
  padding-right: 3rem !important
}

.px-20 {
  padding-left: 5rem !important;
  padding-right: 5rem !important
}

.px-36 {
  padding-left: 9rem !important;
  padding-right: 9rem !important
}

.px-44 {
  padding-left: 11rem !important;
  padding-right: 11rem !important
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important
}

.pt-4 {
  padding-top: 1rem !important
}

.pt-5 {
  padding-top: 1.25rem !important
}

.pt-6 {
  padding-top: 1.5rem !important
}

.pt-8 {
  padding-top: 2rem !important
}

.pt-10 {
  padding-top: 2.5rem !important
}

.pt-14 {
  padding-top: 3.5rem !important
}

.pt-16 {
  padding-top: 4rem !important
}

.pt-20 {
  padding-top: 5rem !important
}

.pt-24 {
  padding-top: 6rem !important
}

.pt-40 {
  padding-top: 10rem !important
}

.pt-56 {
  padding-top: 14rem !important
}

.pr-5 {
  padding-right: 1.25rem !important
}

.pr-8 {
  padding-right: 2rem !important
}

.pr-10 {
  padding-right: 2.5rem !important
}

.pr-12 {
  padding-right: 3rem !important
}

.pr-20 {
  padding-right: 5rem !important
}

.pr-28 {
  padding-right: 7rem !important
}

.pr-32 {
  padding-right: 8rem !important
}

.pr-40 {
  padding-right: 10rem !important
}

.pb-0 {
  padding-bottom: 0px !important
}

.pb-2 {
  padding-bottom: 0.5rem !important
}

.pb-3 {
  padding-bottom: 0.75rem !important
}

.pb-4 {
  padding-bottom: 1rem !important
}

.pb-5 {
  padding-bottom: 1.25rem !important
}

.pb-6 {
  padding-bottom: 1.5rem !important
}

.pb-8 {
  padding-bottom: 2rem !important
}

.pb-10 {
  padding-bottom: 2.5rem !important
}

.pb-12 {
  padding-bottom: 3rem !important
}

.pb-14 {
  padding-bottom: 3.5rem !important
}

.pb-20 {
  padding-bottom: 5rem !important
}

.pb-40 {
  padding-bottom: 10rem !important
}

.pb-3\.5 {
  padding-bottom: 0.875rem !important
}

.pl-2 {
  padding-left: 0.5rem !important
}

.pl-5 {
  padding-left: 1.25rem !important
}

.pl-6 {
  padding-left: 1.5rem !important
}

.pl-8 {
  padding-left: 2rem !important
}

.pl-9 {
  padding-left: 2.25rem !important
}

.pl-10 {
  padding-left: 2.5rem !important
}

.pl-12 {
  padding-left: 3rem !important
}

.pl-16 {
  padding-left: 4rem !important
}

.pl-20 {
  padding-left: 5rem !important
}

.pl-24 {
  padding-left: 6rem !important
}

.pl-28 {
  padding-left: 7rem !important
}

.text-left {
  text-align: left !important
}

.text-center {
  text-align: center !important
}

.text-justify {
  text-align: justify !important
}

.text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important
}

.text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important
}

.font-normal {
  font-weight: 400 !important
}

.font-medium {
  font-weight: 500 !important
}

.font-semibold {
  font-weight: 600 !important
}

.font-bold {
  font-weight: 700 !important
}

.uppercase {
  text-transform: uppercase !important
}

.capitalize {
  text-transform: capitalize !important
}

.italic {
  font-style: italic !important
}

.leading-6 {
  line-height: 1.5rem !important
}

.text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important
}

.underline {
  text-decoration: underline !important
}

.antialiased {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important
}

.opacity-0 {
  opacity: 0 !important
}

.opacity-40 {
  opacity: 0.4 !important
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
}

.blur {
  --tw-blur: blur(8px) !important
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06)) !important
}

.backdrop-filter {
  --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important
}

@media (min-width: 640px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1280px) {
}

@media (min-width: 1536px) {
}

/* purgecss start ignore */

*{
  margin:0;
  padding:0;
  outline:0;
  box-sizing:border-box
}

html{
  font-size:8px
}

@media screen and (min-height: 800px){
  html{
    font-size:7.4px
  }
}

@media screen and (min-height: 850px){
  html{
    font-size:7.87px
  }
}

@media screen and (min-height: 900px){
  html{
    font-size:8.33px
  }
}

@media screen and (min-height: 950px){
  html{
    font-size:8.8px
  }
}

@media screen and (min-height: 1000px){
  html{
    font-size:9.26px
  }
}

@media screen and (min-height: 1050px){
  html{
    font-size:9.72px
  }
}

@media screen and (min-height: 1100px){
  html{
    font-size:10.19px
  }
}

@media screen and (min-height: 1150px){
  html{
    font-size:10.65px
  }
}

@media screen and (min-height: 1200px){
  html{
    font-size:11.26px
  }
}

@media screen and (min-height: 1300px){
  html{
    font-size:12.04px
  }
}

@media screen and (min-height: 1400px){
  html{
    font-size:12.96px
  }
}

@media screen and (min-height: 1400px){
  html{
    font-size:12.96px
  }
}

::-webkit-scrollbar{
  background-color:#fff;
  width:8px;
  height:8px
}

::-webkit-scrollbar-track{
  background-color:#fff
}

::-webkit-scrollbar-thumb{
  background-color:#babac0;
  border-radius:6px;
  border:2px solid #fff
}

::-webkit-scrollbar-thumb and:horizontal{
  background-color:#12c8b2
}

::-webkit-scrollbar-button{
  display:none
}

.medium-editor-placeholder{
  margin-bottom:0 !important;
  line-height:100%
}

.medium-editor-placeholder-relative:after,.medium-editor-placeholder:after{
  position:relative !important;
  font-style:normal !important;
  line-height:100%;
  color:#8090e7 !important;
  pointer-events:none
}

body{
  margin:0;
  font-family:"Inter",sans-serif;
  font-size:1.4rem;
  line-height:1.6;
  padding-top:6.4rem;
  color:#152370;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}

.ant-notification{
  z-index:3001
}

.p-auto{
  padding-top:auto !important;
  padding-bottom:auto !important;
  padding-left:auto !important;
  padding-right:auto !important
}

.pv-auto{
  padding-top:auto !important;
  padding-bottom:auto !important
}

.ph-auto{
  padding-left:auto !important;
  padding-right:auto !important
}

.pt-auto{
  padding-top:auto !important
}

.pb-auto{
  padding-bottom:auto !important
}

.pl-auto{
  padding-left:auto !important
}

.pr-auto{
  padding-right:auto !important
}

.m-auto{
  margin-top:auto !important;
  margin-bottom:auto !important;
  margin-left:auto !important;
  margin-right:auto !important
}

.mv-auto{
  margin-top:auto !important;
  margin-bottom:auto !important
}

.mh-auto{
  margin-left:auto !important;
  margin-right:auto !important
}

.mt-auto{
  margin-top:auto !important
}

.mb-auto{
  margin-bottom:auto !important
}

.ml-auto{
  margin-left:auto !important
}

.mr-auto{
  margin-right:auto !important
}

.p-0{
  padding-top:0 !important;
  padding-bottom:0 !important;
  padding-left:0 !important;
  padding-right:0 !important
}

.pv-0{
  padding-top:0 !important;
  padding-bottom:0 !important
}

.ph-0{
  padding-left:0 !important;
  padding-right:0 !important
}

.pt-0{
  padding-top:0 !important
}

.pb-0{
  padding-bottom:0 !important
}

.pl-0{
  padding-left:0 !important
}

.pr-0{
  padding-right:0 !important
}

.m-0{
  margin-top:0 !important;
  margin-bottom:0 !important;
  margin-left:0 !important;
  margin-right:0 !important
}

.mv-0{
  margin-top:0 !important;
  margin-bottom:0 !important
}

.mh-0{
  margin-left:0 !important;
  margin-right:0 !important
}

.mt-0{
  margin-top:0 !important
}

.mb-0{
  margin-bottom:0 !important
}

.ml-0{
  margin-left:0 !important
}

.mr-0{
  margin-right:0 !important
}

.p-4{
  padding-top:0.4rem !important;
  padding-bottom:0.4rem !important;
  padding-left:0.4rem !important;
  padding-right:0.4rem !important
}

.pv-4{
  padding-top:0.4rem !important;
  padding-bottom:0.4rem !important
}

.ph-4{
  padding-left:0.4rem !important;
  padding-right:0.4rem !important
}

.pt-4{
  padding-top:0.4rem !important
}

.pb-4{
  padding-bottom:0.4rem !important
}

.pl-4{
  padding-left:0.4rem !important
}

.pr-4{
  padding-right:0.4rem !important
}

.m-4{
  margin-top:0.4rem !important;
  margin-bottom:0.4rem !important;
  margin-left:0.4rem !important;
  margin-right:0.4rem !important
}

.mv-4{
  margin-top:0.4rem !important;
  margin-bottom:0.4rem !important
}

.mh-4{
  margin-left:0.4rem !important;
  margin-right:0.4rem !important
}

.mt-4{
  margin-top:0.4rem !important
}

.mb-4{
  margin-bottom:0.4rem !important
}

.ml-4{
  margin-left:0.4rem !important
}

.mr-4{
  margin-right:0.4rem !important
}

.p-8{
  padding-top:0.8rem !important;
  padding-bottom:0.8rem !important;
  padding-left:0.8rem !important;
  padding-right:0.8rem !important
}

.pv-8{
  padding-top:0.8rem !important;
  padding-bottom:0.8rem !important
}

.ph-8{
  padding-left:0.8rem !important;
  padding-right:0.8rem !important
}

.pt-8{
  padding-top:0.8rem !important
}

.pb-8{
  padding-bottom:0.8rem !important
}

.pl-8{
  padding-left:0.8rem !important
}

.pr-8{
  padding-right:0.8rem !important
}

.m-8{
  margin-top:0.8rem !important;
  margin-bottom:0.8rem !important;
  margin-left:0.8rem !important;
  margin-right:0.8rem !important
}

.mv-8{
  margin-top:0.8rem !important;
  margin-bottom:0.8rem !important
}

.mh-8{
  margin-left:0.8rem !important;
  margin-right:0.8rem !important
}

.mt-8{
  margin-top:0.8rem !important
}

.mb-8{
  margin-bottom:0.8rem !important
}

.ml-8{
  margin-left:0.8rem !important
}

.mr-8{
  margin-right:0.8rem !important
}

.p-10{
  padding-top:1rem !important;
  padding-bottom:1rem !important;
  padding-left:1rem !important;
  padding-right:1rem !important
}

.pv-10{
  padding-top:1rem !important;
  padding-bottom:1rem !important
}

.ph-10{
  padding-left:1rem !important;
  padding-right:1rem !important
}

.pt-10{
  padding-top:1rem !important
}

.pb-10{
  padding-bottom:1rem !important
}

.pl-10{
  padding-left:1rem !important
}

.pr-10{
  padding-right:1rem !important
}

.m-10{
  margin-top:1rem !important;
  margin-bottom:1rem !important;
  margin-left:1rem !important;
  margin-right:1rem !important
}

.mv-10{
  margin-top:1rem !important;
  margin-bottom:1rem !important
}

.mh-10{
  margin-left:1rem !important;
  margin-right:1rem !important
}

.mt-10{
  margin-top:1rem !important
}

.mb-10{
  margin-bottom:1rem !important
}

.ml-10{
  margin-left:1rem !important
}

.mr-10{
  margin-right:1rem !important
}

.p-12{
  padding-top:1.2rem !important;
  padding-bottom:1.2rem !important;
  padding-left:1.2rem !important;
  padding-right:1.2rem !important
}

.pv-12{
  padding-top:1.2rem !important;
  padding-bottom:1.2rem !important
}

.ph-12{
  padding-left:1.2rem !important;
  padding-right:1.2rem !important
}

.pt-12{
  padding-top:1.2rem !important
}

.pb-12{
  padding-bottom:1.2rem !important
}

.pl-12{
  padding-left:1.2rem !important
}

.pr-12{
  padding-right:1.2rem !important
}

.m-12{
  margin-top:1.2rem !important;
  margin-bottom:1.2rem !important;
  margin-left:1.2rem !important;
  margin-right:1.2rem !important
}

.mv-12{
  margin-top:1.2rem !important;
  margin-bottom:1.2rem !important
}

.mh-12{
  margin-left:1.2rem !important;
  margin-right:1.2rem !important
}

.mt-12{
  margin-top:1.2rem !important
}

.mb-12{
  margin-bottom:1.2rem !important
}

.ml-12{
  margin-left:1.2rem !important
}

.mr-12{
  margin-right:1.2rem !important
}

.p-14{
  padding-top:1.4rem !important;
  padding-bottom:1.4rem !important;
  padding-left:1.4rem !important;
  padding-right:1.4rem !important
}

.pv-14{
  padding-top:1.4rem !important;
  padding-bottom:1.4rem !important
}

.ph-14{
  padding-left:1.4rem !important;
  padding-right:1.4rem !important
}

.pt-14{
  padding-top:1.4rem !important
}

.pb-14{
  padding-bottom:1.4rem !important
}

.pl-14{
  padding-left:1.4rem !important
}

.pr-14{
  padding-right:1.4rem !important
}

.m-14{
  margin-top:1.4rem !important;
  margin-bottom:1.4rem !important;
  margin-left:1.4rem !important;
  margin-right:1.4rem !important
}

.mv-14{
  margin-top:1.4rem !important;
  margin-bottom:1.4rem !important
}

.mh-14{
  margin-left:1.4rem !important;
  margin-right:1.4rem !important
}

.mt-14{
  margin-top:1.4rem !important
}

.mb-14{
  margin-bottom:1.4rem !important
}

.ml-14{
  margin-left:1.4rem !important
}

.mr-14{
  margin-right:1.4rem !important
}

.p-16{
  padding-top:1.6rem !important;
  padding-bottom:1.6rem !important;
  padding-left:1.6rem !important;
  padding-right:1.6rem !important
}

.pv-16{
  padding-top:1.6rem !important;
  padding-bottom:1.6rem !important
}

.ph-16{
  padding-left:1.6rem !important;
  padding-right:1.6rem !important
}

.pt-16{
  padding-top:1.6rem !important
}

.pb-16{
  padding-bottom:1.6rem !important
}

.pl-16{
  padding-left:1.6rem !important
}

.pr-16{
  padding-right:1.6rem !important
}

.m-16{
  margin-top:1.6rem !important;
  margin-bottom:1.6rem !important;
  margin-left:1.6rem !important;
  margin-right:1.6rem !important
}

.mv-16{
  margin-top:1.6rem !important;
  margin-bottom:1.6rem !important
}

.mh-16{
  margin-left:1.6rem !important;
  margin-right:1.6rem !important
}

.mt-16{
  margin-top:1.6rem !important
}

.mb-16{
  margin-bottom:1.6rem !important
}

.ml-16{
  margin-left:1.6rem !important
}

.mr-16{
  margin-right:1.6rem !important
}

.p-17{
  padding-top:1.7rem !important;
  padding-bottom:1.7rem !important;
  padding-left:1.7rem !important;
  padding-right:1.7rem !important
}

.pv-17{
  padding-top:1.7rem !important;
  padding-bottom:1.7rem !important
}

.ph-17{
  padding-left:1.7rem !important;
  padding-right:1.7rem !important
}

.pt-17{
  padding-top:1.7rem !important
}

.pb-17{
  padding-bottom:1.7rem !important
}

.pl-17{
  padding-left:1.7rem !important
}

.pr-17{
  padding-right:1.7rem !important
}

.m-17{
  margin-top:1.7rem !important;
  margin-bottom:1.7rem !important;
  margin-left:1.7rem !important;
  margin-right:1.7rem !important
}

.mv-17{
  margin-top:1.7rem !important;
  margin-bottom:1.7rem !important
}

.mh-17{
  margin-left:1.7rem !important;
  margin-right:1.7rem !important
}

.mt-17{
  margin-top:1.7rem !important
}

.mb-17{
  margin-bottom:1.7rem !important
}

.ml-17{
  margin-left:1.7rem !important
}

.mr-17{
  margin-right:1.7rem !important
}

.p-20{
  padding-top:2rem !important;
  padding-bottom:2rem !important;
  padding-left:2rem !important;
  padding-right:2rem !important
}

.pv-20{
  padding-top:2rem !important;
  padding-bottom:2rem !important
}

.ph-20{
  padding-left:2rem !important;
  padding-right:2rem !important
}

.pt-20{
  padding-top:2rem !important
}

.pb-20{
  padding-bottom:2rem !important
}

.pl-20{
  padding-left:2rem !important
}

.pr-20{
  padding-right:2rem !important
}

.m-20{
  margin-top:2rem !important;
  margin-bottom:2rem !important;
  margin-left:2rem !important;
  margin-right:2rem !important
}

.mv-20{
  margin-top:2rem !important;
  margin-bottom:2rem !important
}

.mh-20{
  margin-left:2rem !important;
  margin-right:2rem !important
}

.mt-20{
  margin-top:2rem !important
}

.mb-20{
  margin-bottom:2rem !important
}

.ml-20{
  margin-left:2rem !important
}

.mr-20{
  margin-right:2rem !important
}

.p-22{
  padding-top:2.2rem !important;
  padding-bottom:2.2rem !important;
  padding-left:2.2rem !important;
  padding-right:2.2rem !important
}

.pv-22{
  padding-top:2.2rem !important;
  padding-bottom:2.2rem !important
}

.ph-22{
  padding-left:2.2rem !important;
  padding-right:2.2rem !important
}

.pt-22{
  padding-top:2.2rem !important
}

.pb-22{
  padding-bottom:2.2rem !important
}

.pl-22{
  padding-left:2.2rem !important
}

.pr-22{
  padding-right:2.2rem !important
}

.m-22{
  margin-top:2.2rem !important;
  margin-bottom:2.2rem !important;
  margin-left:2.2rem !important;
  margin-right:2.2rem !important
}

.mv-22{
  margin-top:2.2rem !important;
  margin-bottom:2.2rem !important
}

.mh-22{
  margin-left:2.2rem !important;
  margin-right:2.2rem !important
}

.mt-22{
  margin-top:2.2rem !important
}

.mb-22{
  margin-bottom:2.2rem !important
}

.ml-22{
  margin-left:2.2rem !important
}

.mr-22{
  margin-right:2.2rem !important
}

.p-24{
  padding-top:2.4rem !important;
  padding-bottom:2.4rem !important;
  padding-left:2.4rem !important;
  padding-right:2.4rem !important
}

.pv-24{
  padding-top:2.4rem !important;
  padding-bottom:2.4rem !important
}

.ph-24{
  padding-left:2.4rem !important;
  padding-right:2.4rem !important
}

.pt-24{
  padding-top:2.4rem !important
}

.pb-24{
  padding-bottom:2.4rem !important
}

.pl-24{
  padding-left:2.4rem !important
}

.pr-24{
  padding-right:2.4rem !important
}

.m-24{
  margin-top:2.4rem !important;
  margin-bottom:2.4rem !important;
  margin-left:2.4rem !important;
  margin-right:2.4rem !important
}

.mv-24{
  margin-top:2.4rem !important;
  margin-bottom:2.4rem !important
}

.mh-24{
  margin-left:2.4rem !important;
  margin-right:2.4rem !important
}

.mt-24{
  margin-top:2.4rem !important
}

.mb-24{
  margin-bottom:2.4rem !important
}

.ml-24{
  margin-left:2.4rem !important
}

.mr-24{
  margin-right:2.4rem !important
}

.p-30{
  padding-top:3rem !important;
  padding-bottom:3rem !important;
  padding-left:3rem !important;
  padding-right:3rem !important
}

.pv-30{
  padding-top:3rem !important;
  padding-bottom:3rem !important
}

.ph-30{
  padding-left:3rem !important;
  padding-right:3rem !important
}

.pt-30{
  padding-top:3rem !important
}

.pb-30{
  padding-bottom:3rem !important
}

.pl-30{
  padding-left:3rem !important
}

.pr-30{
  padding-right:3rem !important
}

.m-30{
  margin-top:3rem !important;
  margin-bottom:3rem !important;
  margin-left:3rem !important;
  margin-right:3rem !important
}

.mv-30{
  margin-top:3rem !important;
  margin-bottom:3rem !important
}

.mh-30{
  margin-left:3rem !important;
  margin-right:3rem !important
}

.mt-30{
  margin-top:3rem !important
}

.mb-30{
  margin-bottom:3rem !important
}

.ml-30{
  margin-left:3rem !important
}

.mr-30{
  margin-right:3rem !important
}

.p-32{
  padding-top:3.2rem !important;
  padding-bottom:3.2rem !important;
  padding-left:3.2rem !important;
  padding-right:3.2rem !important
}

.pv-32{
  padding-top:3.2rem !important;
  padding-bottom:3.2rem !important
}

.ph-32{
  padding-left:3.2rem !important;
  padding-right:3.2rem !important
}

.pt-32{
  padding-top:3.2rem !important
}

.pb-32{
  padding-bottom:3.2rem !important
}

.pl-32{
  padding-left:3.2rem !important
}

.pr-32{
  padding-right:3.2rem !important
}

.m-32{
  margin-top:3.2rem !important;
  margin-bottom:3.2rem !important;
  margin-left:3.2rem !important;
  margin-right:3.2rem !important
}

.mv-32{
  margin-top:3.2rem !important;
  margin-bottom:3.2rem !important
}

.mh-32{
  margin-left:3.2rem !important;
  margin-right:3.2rem !important
}

.mt-32{
  margin-top:3.2rem !important
}

.mb-32{
  margin-bottom:3.2rem !important
}

.ml-32{
  margin-left:3.2rem !important
}

.mr-32{
  margin-right:3.2rem !important
}

.p-40{
  padding-top:4rem !important;
  padding-bottom:4rem !important;
  padding-left:4rem !important;
  padding-right:4rem !important
}

.pv-40{
  padding-top:4rem !important;
  padding-bottom:4rem !important
}

.ph-40{
  padding-left:4rem !important;
  padding-right:4rem !important
}

.pt-40{
  padding-top:4rem !important
}

.pb-40{
  padding-bottom:4rem !important
}

.pl-40{
  padding-left:4rem !important
}

.pr-40{
  padding-right:4rem !important
}

.m-40{
  margin-top:4rem !important;
  margin-bottom:4rem !important;
  margin-left:4rem !important;
  margin-right:4rem !important
}

.mv-40{
  margin-top:4rem !important;
  margin-bottom:4rem !important
}

.mh-40{
  margin-left:4rem !important;
  margin-right:4rem !important
}

.mt-40{
  margin-top:4rem !important
}

.mb-40{
  margin-bottom:4rem !important
}

.ml-40{
  margin-left:4rem !important
}

.mr-40{
  margin-right:4rem !important
}

.p-42{
  padding-top:4.2rem !important;
  padding-bottom:4.2rem !important;
  padding-left:4.2rem !important;
  padding-right:4.2rem !important
}

.pv-42{
  padding-top:4.2rem !important;
  padding-bottom:4.2rem !important
}

.ph-42{
  padding-left:4.2rem !important;
  padding-right:4.2rem !important
}

.pt-42{
  padding-top:4.2rem !important
}

.pb-42{
  padding-bottom:4.2rem !important
}

.pl-42{
  padding-left:4.2rem !important
}

.pr-42{
  padding-right:4.2rem !important
}

.m-42{
  margin-top:4.2rem !important;
  margin-bottom:4.2rem !important;
  margin-left:4.2rem !important;
  margin-right:4.2rem !important
}

.mv-42{
  margin-top:4.2rem !important;
  margin-bottom:4.2rem !important
}

.mh-42{
  margin-left:4.2rem !important;
  margin-right:4.2rem !important
}

.mt-42{
  margin-top:4.2rem !important
}

.mb-42{
  margin-bottom:4.2rem !important
}

.ml-42{
  margin-left:4.2rem !important
}

.mr-42{
  margin-right:4.2rem !important
}

.p-48{
  padding-top:4.8rem !important;
  padding-bottom:4.8rem !important;
  padding-left:4.8rem !important;
  padding-right:4.8rem !important
}

.pv-48{
  padding-top:4.8rem !important;
  padding-bottom:4.8rem !important
}

.ph-48{
  padding-left:4.8rem !important;
  padding-right:4.8rem !important
}

.pt-48{
  padding-top:4.8rem !important
}

.pb-48{
  padding-bottom:4.8rem !important
}

.pl-48{
  padding-left:4.8rem !important
}

.pr-48{
  padding-right:4.8rem !important
}

.m-48{
  margin-top:4.8rem !important;
  margin-bottom:4.8rem !important;
  margin-left:4.8rem !important;
  margin-right:4.8rem !important
}

.mv-48{
  margin-top:4.8rem !important;
  margin-bottom:4.8rem !important
}

.mh-48{
  margin-left:4.8rem !important;
  margin-right:4.8rem !important
}

.mt-48{
  margin-top:4.8rem !important
}

.mb-48{
  margin-bottom:4.8rem !important
}

.ml-48{
  margin-left:4.8rem !important
}

.mr-48{
  margin-right:4.8rem !important
}

.p-50{
  padding-top:5rem !important;
  padding-bottom:5rem !important;
  padding-left:5rem !important;
  padding-right:5rem !important
}

.pv-50{
  padding-top:5rem !important;
  padding-bottom:5rem !important
}

.ph-50{
  padding-left:5rem !important;
  padding-right:5rem !important
}

.pt-50{
  padding-top:5rem !important
}

.pb-50{
  padding-bottom:5rem !important
}

.pl-50{
  padding-left:5rem !important
}

.pr-50{
  padding-right:5rem !important
}

.m-50{
  margin-top:5rem !important;
  margin-bottom:5rem !important;
  margin-left:5rem !important;
  margin-right:5rem !important
}

.mv-50{
  margin-top:5rem !important;
  margin-bottom:5rem !important
}

.mh-50{
  margin-left:5rem !important;
  margin-right:5rem !important
}

.mt-50{
  margin-top:5rem !important
}

.mb-50{
  margin-bottom:5rem !important
}

.ml-50{
  margin-left:5rem !important
}

.mr-50{
  margin-right:5rem !important
}

.p-60{
  padding-top:6rem !important;
  padding-bottom:6rem !important;
  padding-left:6rem !important;
  padding-right:6rem !important
}

.pv-60{
  padding-top:6rem !important;
  padding-bottom:6rem !important
}

.ph-60{
  padding-left:6rem !important;
  padding-right:6rem !important
}

.pt-60{
  padding-top:6rem !important
}

.pb-60{
  padding-bottom:6rem !important
}

.pl-60{
  padding-left:6rem !important
}

.pr-60{
  padding-right:6rem !important
}

.m-60{
  margin-top:6rem !important;
  margin-bottom:6rem !important;
  margin-left:6rem !important;
  margin-right:6rem !important
}

.mv-60{
  margin-top:6rem !important;
  margin-bottom:6rem !important
}

.mh-60{
  margin-left:6rem !important;
  margin-right:6rem !important
}

.mt-60{
  margin-top:6rem !important
}

.mb-60{
  margin-bottom:6rem !important
}

.ml-60{
  margin-left:6rem !important
}

.mr-60{
  margin-right:6rem !important
}

.p-65{
  padding-top:6.5rem !important;
  padding-bottom:6.5rem !important;
  padding-left:6.5rem !important;
  padding-right:6.5rem !important
}

.pv-65{
  padding-top:6.5rem !important;
  padding-bottom:6.5rem !important
}

.ph-65{
  padding-left:6.5rem !important;
  padding-right:6.5rem !important
}

.pt-65{
  padding-top:6.5rem !important
}

.pb-65{
  padding-bottom:6.5rem !important
}

.pl-65{
  padding-left:6.5rem !important
}

.pr-65{
  padding-right:6.5rem !important
}

.m-65{
  margin-top:6.5rem !important;
  margin-bottom:6.5rem !important;
  margin-left:6.5rem !important;
  margin-right:6.5rem !important
}

.mv-65{
  margin-top:6.5rem !important;
  margin-bottom:6.5rem !important
}

.mh-65{
  margin-left:6.5rem !important;
  margin-right:6.5rem !important
}

.mt-65{
  margin-top:6.5rem !important
}

.mb-65{
  margin-bottom:6.5rem !important
}

.ml-65{
  margin-left:6.5rem !important
}

.mr-65{
  margin-right:6.5rem !important
}

.p-70{
  padding-top:7rem !important;
  padding-bottom:7rem !important;
  padding-left:7rem !important;
  padding-right:7rem !important
}

.pv-70{
  padding-top:7rem !important;
  padding-bottom:7rem !important
}

.ph-70{
  padding-left:7rem !important;
  padding-right:7rem !important
}

.pt-70{
  padding-top:7rem !important
}

.pb-70{
  padding-bottom:7rem !important
}

.pl-70{
  padding-left:7rem !important
}

.pr-70{
  padding-right:7rem !important
}

.m-70{
  margin-top:7rem !important;
  margin-bottom:7rem !important;
  margin-left:7rem !important;
  margin-right:7rem !important
}

.mv-70{
  margin-top:7rem !important;
  margin-bottom:7rem !important
}

.mh-70{
  margin-left:7rem !important;
  margin-right:7rem !important
}

.mt-70{
  margin-top:7rem !important
}

.mb-70{
  margin-bottom:7rem !important
}

.ml-70{
  margin-left:7rem !important
}

.mr-70{
  margin-right:7rem !important
}

.hide-scrollbar::-webkit-scrollbar{
  display:none
}

.hide-scrollbar{
  -ms-overflow-style:none;
  scrollbar-width:none
}

/* purgecss end ignore */
