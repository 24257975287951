@font-face {
  font-family: 'Roca One';
  src: url(./assets/fonts/RocaOne.otf);
}
.app,
#root {
  height: 100%;
  width: 100%;
}
.app {
  display: flex;
  flex-direction: column;
}
img {
  max-width: 100%;
}
